import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
// import About from "../components/About/About";
import config from "../../data/SiteConfig";
import Header from "../components/Header/Header";
import Services from "../components/Services/Services";
import Slider from "../components/Slider/Slider";
import Footer from "../components/Footer/Footer";
import Maintenance from "../components/Maintenance/Maintenance";
import AdminConfig from "../../data/AdminConfig.json";

class IndexPage extends Component {
  render() {
    return (
      <>
        {AdminConfig.siteMaintenance ? (
          <Maintenance />
        ) : (
          <Layout>
            <Helmet title={`${config.siteTitle}`} />
            <>
              <Slider />
              <Services />
            </>
          </Layout>
        )}
      </>
    );
  }
}

export default IndexPage;
