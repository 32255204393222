import React, { Component } from "react";
import "./Maintenance.css";
import config from "../../../data/SiteConfig";
import content from "../../../data/SiteContent";

class Maintenance extends Component {
  render() {
    return (
      <div className="maintenance-landing">
        <div className="maintenanceWrapper">
          <img src={config.siteLogo} />
          <h2>{content.maintenanceTitle}</h2>
          <br />
          <a href={`mailto:${config.companyEmail}`}> {config.companyEmail}</a>
        </div>
      </div>
    );
  }
}
export default Maintenance;
