import React, { Component } from "react";
import { Link } from "gatsby";
import "./Services.css";
import ServicesData from "../../../data/AdminServices.json";

class Services extends Component {
  render() {
    return (
      <div className="container">
        <section className="section">
          <h2 className="heading">{ServicesData.title}</h2>
          <div className="headingLine" />
          <div className="contentGrid">
            {ServicesData.items.map((data, index) => {
              return (
                <div
                  key={`content_item_${index}`}
                  className="contentBoxWrapper"
                >
                  <div className="contentBoxImage">
                    <img src={data.image} alt={data.title} />
                  </div>
                  <div className="contentBoxContent">
                    <h3 className='content-title'>{data.title}</h3>
                    <p>{data.desc}</p>
                    <Link to="/iletisim" title="iletisim">
                      <button className="buttonBlack">Hemen Destek Al</button>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="contentGrid">
            {ServicesData.otherItems.map((data, index) => {
              return (
                <div
                  key={`content_item_${index}`}
                  className="contentBoxWrapper"
                >
                  <div className="contentBoxImage">
                    <img src={data.image} alt={data.title} />
                  </div>
                  <div className="contentBoxContent">
                    <h3 className='content-title'>{data.title}</h3>
                    <p>{data.desc}</p>
                    <Link to="/iletisim" title="iletisim">
                      <button className="buttonBlack">Hemen Destek Al</button>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    );
  }
}

export default Services;
