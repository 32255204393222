import React, { Component } from "react";
import { Link } from "gatsby";
import "./Slider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import SliderData from "../../../data/AdminSlider.json";
import config from "../../../data/SiteConfig";

class SliderSection extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplaySpeed: 4000,
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <>
        <Slider {...settings}>
          {SliderData.sliderItems.map((data, index) => {
            return (
              <div key={`slider_item_${index}`}>
                <div className="slider-container">
                  <img
                    className="slider-img"
                    alt={data.title}
                    src={data.image}
                  />
                  <div className="container">
                    <div className="slider-wrapper">
                      <div className="slider-text-section">
                        {data.logo ? (
                          <img
                            className="slider-logo"
                            src={data.logo}
                            alt="Logo"
                          />
                        ) : null}
                        <h1 className="slider-title">{data.title}</h1>
                        <p className="slider-desc">
                          {data.desc ? data.desc : null}
                        </p>
                        {data.link ? (
                          <Link to={data.link} title={data.title}>
                            <button className="button">Devam</button>
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </>
    );
  }
}

export default SliderSection;
